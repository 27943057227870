import { Observable, of as observableOf, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BackendServiceContract } from './backend.service.interface';
import { LogService } from './log/log.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UploadDocument } from '@ssmm-shared/data/models/document/upload-document.interface';
import { DownloadDocument } from '@ssmm-shared/data/models/document/download-document.interface';
import getQueryParamSeparator from '@ssmm-shared/helpers/get-query-param-separator';

/**
 * @param T: Type of GET request
 * @param U: Type of PUT, POST, DELETE request
 */
@Injectable()
export class BackendService<T, U> implements BackendServiceContract<T, U> {
  protected readonly _baseUrl: string;

  constructor(
    private _http: HttpClient,
    private _log: LogService,
    private _sanitizer: DomSanitizer
  ) {
    this._baseUrl =
      environment.apiBaseUrl !== '/'
        ? environment.apiBaseUrl
        : document.location.origin;
  }

  getItem(uri: string, params?: object | undefined): Observable<T> {
    const baseUrl = this.getBaseUrl();
    let url = new URL(uri, baseUrl);
    let queryParams: HttpParams | undefined = void 0;

    if (params) {
      const urlParts = uri.split('?');
      url = new URL(urlParts[0], baseUrl);
      const originalQueryParams = urlParts.length === 2 ? urlParts[1] : void 0;

      queryParams = this.getQueryParams(params, originalQueryParams);
    }

    return this._http.get(url.href, { params: queryParams }).pipe(
      map(res => <T>res),
      catchError(e => this.errHandlerGeneric(e))
    );
  }

  getItems(
    url: string,
    pageSize = 0,
    skip = 0,
    filter: string = null
  ): Observable<T> {
    let urlResolved = new URL(url, this._baseUrl).href;
    let isFirstParam = !urlResolved.includes('?');

    // If paging is requested, pageSize will never be 0
    if (pageSize) {
      urlResolved =
        urlResolved +
        getQueryParamSeparator(isFirstParam) +
        `offset=${skip}&limit=${pageSize}`;
      isFirstParam = false;
    }

    // Add search term, if provided
    if (filter) {
      urlResolved = urlResolved + getQueryParamSeparator(isFirstParam) + filter;
    }

    return this._http.get(urlResolved).pipe(
      map(res => <T>res),
      catchError(err => this.errHandlerGeneric(err))
    );
  }

  getItems2(uri: string, params: HttpParams): Observable<T> {
    const urlResolved = new URL(uri, this._baseUrl).href;

    return this._http.get(urlResolved, { params }).pipe(
      map(res => <T>res),
      catchError(err => this.errHandlerGeneric(err))
    );
  }

  getBlob(url: string): Observable<SafeUrl> {
    const urlResolved = new URL(url, this._baseUrl).href;

    return this._http
      .get(urlResolved, { responseType: 'blob', observe: 'response' })
      .pipe(
        map(res => {
          const blob = (<HttpResponse<Blob>>res).body;
          const objectUrl = URL.createObjectURL(blob);
          return this._sanitizer.bypassSecurityTrustUrl(objectUrl);
        }),
        catchError(err => this.errHandlerBlob(err))
      );
  }

  getDocumentSafeUrl(url: string): Observable<DownloadDocument> {
    const urlResolved = new URL(url, this._baseUrl).href;

    return this._http
      .get(urlResolved, { responseType: 'blob', observe: 'response' })
      .pipe(
        map(res => {
          const blob = (<HttpResponse<Blob>>res).body;
          const objectUrl = URL.createObjectURL(blob);

          return <DownloadDocument>{
            mimeType: res.headers.get('content-type'),
            fileName: res.headers
              .get('content-disposition')
              .match(/filename=["'](.*)["']/)[1],
            safeUrl: this._sanitizer.bypassSecurityTrustUrl(objectUrl),
            blob: blob
          };
        }),
        catchError(err => this.errHandlerDownloadDocument(err))
      );
  }

  getPictograms(urls: string[]): Observable<SafeUrl[]> {
    const obsArr: Observable<SafeUrl>[] = [];
    urls.forEach(u => {
      obsArr.push(u ? this.getBlob(u) : observableOf(null));
    });

    return forkJoin(obsArr);
  }

  saveItem(
    uri: string,
    item: U,
    params?: HttpParams,
    throwErrors?: boolean
  ): Observable<number> {
    const saveUrlResolved = new URL(uri, this._baseUrl).href;

    return this._http.post(saveUrlResolved, item, { params }).pipe(
      map(() => 200),
      catchError(err => {
        if (throwErrors) {
          throw err;
        }

        return this.errHandlerStatusCode(err);
      })
    );
  }

  saveItemWithResponseType(url: string, item: U): Observable<T> {
    const saveUrlResolved = new URL(url, this._baseUrl).href;

    return this._http.post(saveUrlResolved, item).pipe(
      map(res => <T>res),
      catchError(err => this.errHandlerGenericRethrow<T>(err))
    );
  }

  saveItems<V>(url: string, items: V[]): Observable<boolean> {
    const saveUrlResolved = new URL(url, this._baseUrl).href;

    return this._http.post(saveUrlResolved, items).pipe(
      map(() => true),
      catchError(err => this.errHandlerBoolean(err))
    );
  }

  /**
   * Updates an item
   * @param uri The unresolved backend url
   * @param item The item to update
   * @param params The query parameters
   * @param throwErrors The flag if errors should be thrown
   * @returns HTTP status code
   */
  updateItem(
    uri: string,
    item: U,
    params?: HttpParams,
    throwErrors?: boolean
  ): Observable<number> {
    const urlResolved = new URL(uri, this._baseUrl).href;

    return this._http.put(urlResolved, item, { params }).pipe(
      map(() => 200),
      catchError(err => {
        if (throwErrors) {
          throw err;
        }

        return this.errHandlerStatusCode(err);
      })
    );
  }

  deleteItem(url: string): Observable<boolean> {
    const deleteUrlResolved = new URL(url, this._baseUrl).href;

    return this._http.delete(deleteUrlResolved).pipe(
      map(() => true),
      catchError(err => this.errHandlerBoolean(err))
    );
  }

  /**
   * Upload a document to the server
   * @param uri The backend Uri
   * @param item The document to upload
   * @see https://stackoverflow.com/questions/93551
   */
  uploadDocument(url: string, item: File): Observable<UploadDocument> {
    const saveUrlResolved = new URL(url, this._baseUrl).href;

    const headers = new HttpHeaders({
      'Content-Disposition': 'attachment; filename="' + item.name + '"'
    });

    const options = { headers: headers };

    return this._http
      .post(saveUrlResolved, item, options)
      .pipe(map(doc => <UploadDocument>doc));
  }

  /**
   * Gets the origin url of the backend which should be used.
   * @returns The base url defined in the angular environment files if different from window.location.origin
   */
  private getBaseUrl(): string {
    // length of 1 means '/'
    return this._baseUrl.length > 1 ? this._baseUrl : window.location.origin;
  }

  private getQueryParams(
    newQueryParams: object | undefined,
    originalQueryParams: string | undefined
  ): HttpParams {
    let queryParams = newQueryParams
      ? new HttpParams({ fromObject: { ...newQueryParams } })
      : new HttpParams();

    if (originalQueryParams) {
      const originalQueryParamsParts = originalQueryParams.split('&');

      originalQueryParamsParts.forEach(p => {
        const keyValuePair = p.split('=');
        if (!queryParams.has(keyValuePair[0])) {
          queryParams = queryParams.append(keyValuePair[0], keyValuePair[1]);
        }
      });
    }

    return queryParams;
  }

  private errHandlerStatusCode(err: HttpErrorResponse): Observable<number> {
    this._log.error(JSON.stringify(err));
    return observableOf<number>(err.status);
  }

  private errHandlerGeneric(err: any): Observable<T> {
    const response = <Response>err;
    this._log.error(JSON.stringify(response));
    return observableOf<T>(<T>{});
  }

  private errHandlerGenericRethrow<V>(err: HttpErrorResponse): Observable<V> {
    this._log.error(JSON.stringify(err));
    throw err;
  }

  private errHandlerBoolean(err: Response): Observable<boolean> {
    this._log.error(JSON.stringify(err));
    return observableOf<boolean>(false);
  }

  private errHandlerBlob(err: HttpErrorResponse): Observable<SafeUrl> {
    if (err.status === 404) {
      this._log.debug('No Blob found for given URL');
      return observableOf<SafeUrl>(<SafeUrl>{});
    }

    this._log.error('Error loading blob: ' + err);

    return observableOf<SafeUrl>(<SafeUrl>{});
  }

  private errHandlerDownloadDocument(
    err: HttpErrorResponse
  ): Observable<DownloadDocument> {
    if (err.status === 404) {
      this._log.debug('No Blob found for given URL');
      return observableOf<DownloadDocument>(<DownloadDocument>{});
    }

    this._log.error('Error loading download document: ' + err);

    return observableOf<DownloadDocument>(<DownloadDocument>{});
  }
}
