import { UntypedFormGroup } from '@angular/forms';
import { FieldFormat } from '../../data/models/fixture/field-format.enum';
import { Component, Input } from '@angular/core';
import { VlFixtureParticipantDynamicSpec } from '@ssmm-shared/data/models/fixture/vl-fixture-participant-meldeleistung-spec.interface';
import maskedTextboxRules from '@ssmm-shared/data/masked-textbox-rules';

@Component({
  selector: 'ssmm-meldeleistungen',
  templateUrl: './meldeleistungen.component.html'
})
export class MeldeleistungenComponent {
  @Input() specs: VlFixtureParticipantDynamicSpec[];

  @Input() wasSaveRequested: boolean;

  @Input() meldeleistungFormGroup: UntypedFormGroup;

  @Input() isStaffel: boolean;

  fieldFormatDefault = FieldFormat.TYPE_DEFAULT;

  rules = maskedTextboxRules;
}
