import { UntypedFormControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { VlFixtureParticipantDynamicSpec } from '@ssmm-shared/data/models/fixture/vl-fixture-participant-meldeleistung-spec.interface';
import { DataType } from '@ssmm-shared/data/data-type.enum';

@Component({
  selector: 'ssmm-dynamic-addon',
  templateUrl: './dynamic-addon.component.html'
})
export class DynamicAddonComponent {
  @Input() addon: VlFixtureParticipantDynamicSpec;
  @Input() control: UntypedFormControl;
  @Input() isValidationActive: boolean;

  readonly dataTypeString = DataType.STRING;
  readonly dataTypeInt = DataType.INT;
  readonly dataTypeBoolean = DataType.BOOLEAN;
}
