import { FieldFormat } from '../data/models/fixture/field-format.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFormatPrompt'
})
export class DataFormatPromptPipe implements PipeTransform {
  transform(format: FieldFormat): string {
    switch (format) {
      case FieldFormat.DD_DD_DD__MINUTES_SECONDS_HUNDRETH:
        return 'mm:ss,cc';
      case FieldFormat.DD_DD__SECONDS_HUNDRETH:
        return 'ss,cc';
      // D and DD share the same label
      case FieldFormat.D_DD__METRES_CENTIMETRES:
      case FieldFormat.DD_DD__METRES_CENTIMETRES:
        return 'm,cm';
      case FieldFormat.TYPE_DEFAULT:
        return null;
      default:
        throw new Error('Unknown field format ' + format);
    }
  }
}
