import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { KuerzelNameAenderbar } from '@ssmm-shared/data/models/kuerzel-name-aenderbar.interface';
import { ValueKeyListValidators } from './validators/value-key-list-validators';

@Component({
  selector: 'ssmm-value-key-list',
  templateUrl: './value-key-list.component.html'
})
export class ValueKeyListComponent implements OnInit {
  @Input() valueHeader: string;
  @Input() keyHeader: string;

  @Input() isValueRequired: boolean;
  @Input() isKeyRequired: boolean;
  @Input() areDuplicatesAllowed: boolean;

  @Input() valueMaxLength: number;
  @Input() keyMaxLength: number;

  @Input() listItems: KuerzelNameAenderbar[];

  @Output() listChanged = new EventEmitter();

  formGroup: UntypedFormGroup;
  formModel = {
    attributeName: new UntypedFormControl(),
    attributeKuerzel: new UntypedFormControl()
  };

  wasSaveRequested: boolean;

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup(this.formModel);

    this.setValueCtlValidators();
    this.setKeyCtlValidators();
    this.setFormValidators();

    if (!this.listItems?.length) {
      this.listItems = [];
    }
  }

  addEntry(): void {
    this.wasSaveRequested = true;
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (!this.formGroup.valid) {
      return;
    }

    this.listItems.push(<KuerzelNameAenderbar>{
      name: this.formModel.attributeName.value,
      kuerzel: this.formModel.attributeKuerzel.value,
      aenderbar: true
    });

    this.setFormValidators();

    this.formGroup.reset();
    this.wasSaveRequested = false;

    this.listChanged.emit();
  }

  removeEntry(item: KuerzelNameAenderbar): void {
    this.listItems = this.listItems.filter(i => i.kuerzel !== item.kuerzel);

    this.setFormValidators();

    this.listChanged.emit();
  }

  private setKeyCtlValidators(): void {
    this.formModel.attributeKuerzel.setValidators(this.getKeyValidators());
  }

  private setValueCtlValidators(): void {
    this.formModel.attributeName.setValidators(this.getValueValidators());
  }

  private setFormValidators(): void {
    if (this.areDuplicatesAllowed) {
      return;
    }

    this.formGroup.setValidators(
      ValueKeyListValidators.isNoDuplicate(this.listItems)
    );
  }

  private getKeyValidators(): ValidatorFn[] {
    const keyValidators: ValidatorFn[] = [];

    if (this.isKeyRequired) {
      keyValidators.push(Validators.required);
    }
    if (this.keyMaxLength) {
      keyValidators.push(Validators.maxLength(this.keyMaxLength));
    }

    return keyValidators;
  }

  private getValueValidators(): ValidatorFn[] {
    const valueValidators: ValidatorFn[] = [];

    if (this.isValueRequired) {
      valueValidators.push(Validators.required);
    }
    if (this.valueMaxLength) {
      valueValidators.push(Validators.maxLength(this.valueMaxLength));
    }

    return valueValidators;
  }
}
