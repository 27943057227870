import { Fixture } from '@ssmm-shared/data/models/fixture/fixture.interface';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ssmm-fixture-summary',
  templateUrl: './fixture-summary.component.html',
  styleUrls: ['./fixture-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixtureSummaryComponent {
  @Input() fixture: Fixture;
  @Input() competitionName: string;
}
